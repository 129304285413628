import {
  communitiesGet,
  useCommunitiesGet,
} from "@internal/rest/generated/queries/communities";
import { dealsCreateDraft } from "@internal/rest/generated/queries/drafts";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { useRouter } from "next/router";
import { useState } from "react";
import { StepUrls } from "screens/create-deal/steps";
import { handleResourceEffect } from "utility/async";
import { mapSubscriptionToPricePlan } from "utility/pricing";

export function useCreateDealOptions() {
  const router = useRouter();
  const communityHandle = router.query["handle"] as string | undefined;
  const isLaunchingFromCommunity = !!communityHandle;

  const { data: communityGetData } = useCommunitiesGet(
    communityHandle as string,
    {
      query: { enabled: isLaunchingFromCommunity },
    }
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "FOUNDER" | "SYNDICATE-PRIMARY" | "SYNDICATE-SECONDARY"
  >();

  async function onContinueClick() {
    setIsSubmitting(true);
    const isSyndicateDeal = selectedOption !== "FOUNDER";

    await handleResourceEffect({
      async action() {
        let communityData = communityGetData;

        if (!communityData && isLaunchingFromCommunity) {
          communityData = await communitiesGet(communityHandle);
        }

        const response = await dealsCreateDraft({
          // common fields
          terms: {
            base_currency: BaseCurrency.gbp,
            ...(isSyndicateDeal
              ? {
                  transaction_type:
                    selectedOption === "SYNDICATE-PRIMARY"
                      ? "primary"
                      : "secondary",
                }
              : {
                  proxy_voting_rights: "founders",
                }),
          },

          // launched from deal page specific fields
          ...(!isLaunchingFromCommunity
            ? {
                pricing_plan: isSyndicateDeal
                  ? PricePlanName.base_2023
                  : PricePlanName.founder_2023_09,
              }
            : {}),

          // launched from community specific fields
          ...(communityData?.admin
            ? {
                syndicate_id: communityData.id,
                pricing_plan: mapSubscriptionToPricePlan(
                  communityData.admin.subscription_tier
                ),
              }
            : {}),
        });

        if (!response || !response.id) throw new Error("Creating draft failed");

        await router.push(
          `/create-deal/${response?.id}/${
            isSyndicateDeal ? StepUrls.SelectSyndicate : StepUrls.CompanyInfo
          }`
        );
      },
      cleanup() {
        setIsSubmitting(false);
      },
      error: {
        title: "There was an error launching your deal",
      },
    });
  }

  return {
    isSubmitting,
    onContinueClick,
    setSelectedOption,
    selectedOption,
    isLaunchingFromCommunity,
  };
}
