import { Box, VStack } from "@@panda/jsx";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { Typography } from "components/display/Typography/Typography";
import { Alert } from "components/feedback/Alert";
import { Link } from "components/interaction/Link";
import { Button } from "components/main/Button";
import { ModalOption } from "components/modal/ModalOption";

import { useCreateDealOptions } from "./useCreateDealOptions";

export const createDealModalText = {
  cta: "Continue",
  options: {
    syndicatePrimary: {
      title: "Primary Deal",
      description: "Invest directly in a company with syndicate members.",
    },
    syndicateSecondary: {
      title: "Secondary Deal",
      description:
        "Purchase shares from company shareholders with syndicate members.",
    },
    founder: {
      title: "Raise a Round",
      description: "Pool your investors into a single entity (SPV).",
    },
  },
};

export function CreateDealOptions() {
  const {
    onContinueClick,
    isSubmitting,
    selectedOption,
    setSelectedOption,
    isLaunchingFromCommunity,
  } = useCreateDealOptions();

  return (
    <>
      <VStack gap="m" alignItems="flex-start">
        <Box mt="2rem">
          <Alert variant="info" alignIcon="flex-start">
            <Alert.Title> New Investments Temporarily Paused </Alert.Title>
            As of 16/08/2024, Odin has temporarily paused new investments on the
            platform, in order to make some adjustments to our operating model.
            <br />
            <p>
              If your investors have already made a commitment to a particular
              deal, they will not be affected by this. Odin aims to restore full
              functionality within six weeks. For more details,{" "}
              <Link href="/investments-updates-pause">
                please see this statement
              </Link>
              .
            </p>{" "}
            <p>
              For any questions, please contact
              <Link href="mailto:hello@joinodin.com"> hello@joinodin.com</Link>.
            </p>
          </Alert>
        </Box>

        <Typography variant="heading.4">I'm a Syndicate Lead</Typography>
        <ModalOption
          testId="create-deal-syndicate-primary-option"
          title={createDealModalText.options.syndicatePrimary.title}
          description={createDealModalText.options.syndicatePrimary.description}
          icon={BuildingLibraryIcon}
          onClick={() => setSelectedOption("SYNDICATE-PRIMARY")}
          selected={selectedOption === "SYNDICATE-PRIMARY"}
        />
        <ModalOption
          testId="create-deal-syndicate-secondary-option"
          title={createDealModalText.options.syndicateSecondary.title}
          description={
            createDealModalText.options.syndicateSecondary.description
          }
          icon={UserGroupIcon}
          onClick={() => setSelectedOption("SYNDICATE-SECONDARY")}
          selected={selectedOption === "SYNDICATE-SECONDARY"}
        />
        {isLaunchingFromCommunity ? null : (
          <>
            <Typography variant="heading.4">I'm a Founder</Typography>
            <ModalOption
              testId="create-deal-founder-option"
              title={createDealModalText.options.founder.title}
              description={createDealModalText.options.founder.description}
              icon={BanknotesIcon}
              onClick={() => setSelectedOption("FOUNDER")}
              selected={selectedOption === "FOUNDER"}
            />
          </>
        )}
      </VStack>

      <Button
        variant="primary"
        isDisabled={!selectedOption || isSubmitting}
        isLoading={isSubmitting}
        mt="2rem"
        width="full"
        type="submit"
        onClick={onContinueClick}
      >
        {createDealModalText.cta}
      </Button>
    </>
  );
}
