import { css } from "@@panda/css";
import { HTMLStyledProps } from "@@panda/jsx";
import { Card } from "components/display/Card";
import { IconText } from "components/main/IconText";
import { ComponentPropsWithRef, ReactNode } from "react";

interface Props extends HTMLStyledProps<"div"> {
  description: string;
  icon: (svgProps: ComponentPropsWithRef<"svg">) => ReactNode;
  selected: boolean;
  title: string;
  testId?: string;
  onClick: () => void;
}

export function ModalOption({
  description,
  icon,
  selected,
  title,
  onClick,
  ...rest
}: Props) {
  return (
    <Card
      cursor="pointer"
      p="m"
      width="full"
      aria-selected={selected}
      aria-label={title}
      aria-description={description}
      borderRadius="md"
      withBorder
      withHover
      borderColor="grey.dune"
      className={css({
        _selected: {
          borderColor: "brand.krilin!",
        },
      })}
      {...rest}
      onClick={onClick}
    >
      <IconText
        selected={selected}
        title={title}
        description={description}
        icon={icon}
      />
    </Card>
  );
}
